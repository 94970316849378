import React from 'react';

const Sessions = () => {
  return (
    <div className="tracker__sessions">
      <p>Here is a session sentence.</p>
    </div>
  );
};

export default Sessions;
