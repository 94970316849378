export const DataTotals = {
  cases: 1164,
  deaths: 34,
  recovered: 886,
  active: 244,
  hospitalized: 19,
  tested: 28367,
};

export const Data = [
  { cases: 1, deaths: 0, date: '2020-03-10' },
  { cases: 2, deaths: 0, growthFactor: 2, date: '2020-03-11' },
  { cases: 2, deaths: 0, growthFactor: 1, date: '2020-03-12' },
  { cases: 5, deaths: 0, growthFactor: 2.5, date: '2020-03-13' },
  { cases: 5, deaths: 0, growthFactor: 1, date: '2020-03-14' },
  { cases: 5, deaths: 0, growthFactor: 1, date: '2020-03-15' },
  { cases: 5, deaths: 0, growthFactor: 1, date: '2020-03-16' },
  { cases: 10, deaths: 0, growthFactor: 2, date: '2020-03-17' },
  { cases: 13, deaths: 0, growthFactor: 1.3, date: '2020-03-18' },
  { cases: 19, deaths: 0, growthFactor: 1.46, date: '2020-03-19' },
  { cases: 17, deaths: 0, growthFactor: 0.89, date: '2020-03-20' },
  { cases: 19, deaths: 0, growthFactor: 1.12, date: '2020-03-21' },
  { cases: 26, deaths: 0, growthFactor: 1.37, date: '2020-03-22' },
  { cases: 35, deaths: 1, growthFactor: 1.35, date: '2020-03-23' },
  { cases: 35, deaths: 1, growthFactor: 1, date: '2020-03-24' },
  { cases: 39, deaths: 1, growthFactor: 1.11, date: '2020-03-25' },
  { cases: 61, deaths: 1, growthFactor: 1.56, date: '2020-03-26' },
  { cases: 83, deaths: 3, growthFactor: 1.36, date: '2020-03-27' },
  { cases: 98, deaths: 3, growthFactor: 1.18, date: '2020-03-28' },
  { cases: 109, deaths: 4, growthFactor: 1.11, date: '2020-03-29' },
  { cases: 126, deaths: 4, growthFactor: 1.16, date: '2020-03-30' },
  {
    cases: 149,
    deaths: 5,
    recovered: 27,
    active: 117,
    tested: 2987,
    growthFactor: 1.18,
    date: '2020-03-31',
  },
  {
    cases: 160,
    deaths: 5,
    recovered: 31,
    active: 124,
    tested: 2987,
    growthFactor: 1.07,
    date: '2020-04-01',
  },
  {
    cases: 177,
    deaths: 6,
    recovered: 36,
    active: 135,
    tested: 3367,
    hospitalized: 40,
    growthFactor: 1.11,
    date: '2020-04-02',
  },
  {
    cases: 189,
    deaths: 6,
    recovered: 50,
    active: 133,
    tested: 3537,
    hospitalized: 42,
    growthFactor: 1.07,
    date: '2020-04-03',
  },
  {
    cases: 203,
    deaths: 6,
    recovered: 60,
    active: 137,
    tested: 3892,
    hospitalized: 45,
    growthFactor: 1.07,
    date: '2020-04-04',
  },
  {
    cases: 221,
    deaths: 6,
    recovered: 62,
    active: 153,
    tested: 3892,
    hospitalized: 45,
    growthFactor: 1.09,
    date: '2020-04-05',
  },
  {
    cases: 226,
    deaths: 6,
    recovered: 64,
    active: 156,
    tested: 4156,
    hospitalized: 45,
    growthFactor: 1.02,
    date: '2020-04-06',
  },
  {
    cases: 243,
    deaths: 6,
    recovered: 89,
    active: 148,
    tested: 4289,
    hospitalized: 49,
    growthFactor: 1.08,
    date: '2020-04-07',
  },
  {
    cases: 263,
    deaths: 7,
    recovered: 96,
    active: 160,
    tested: 4942,
    hospitalized: 52,
    growthFactor: 1.08,
    date: '2020-04-08',
  },
  {
    cases: 274,
    deaths: 7,
    recovered: 108,
    active: 159,
    tested: 5247,
    hospitalized: 54,
    growthFactor: 1.04,
    date: '2020-04-09',
  },
  {
    cases: 298,
    deaths: 10,
    recovered: 111,
    active: 177,
    tested: 5522,
    hospitalized: 59,
    growthFactor: 1.09,
    date: '2020-04-10',
  },
  {
    cases: 309,
    deaths: 10,
    recovered: 111,
    active: 188,
    tested: 5522,
    hospitalized: 59,
    growthFactor: 1.04,
    date: '2020-04-11',
  },
  {
    cases: 314,
    deaths: 10,
    recovered: 172,
    active: 132,
    tested: 5522,
    hospitalized: 64,
    growthFactor: 1.02,
    date: '2020-04-12',
  },
  {
    cases: 314,
    deaths: 10,
    recovered: 172,
    active: 132,
    tested: 5522,
    hospitalized: 64,
    growthFactor: 1,
    date: '2020-04-13',
  },
  {
    cases: 317,
    deaths: 10,
    recovered: 133,
    active: 174,
    tested: 5806,
    growthFactor: 1.01,
    date: '2020-04-14',
  },
  {
    cases: 365,
    deaths: 13,
    recovered: 166,
    active: 186,
    tested: 6508,
    growthFactor: 1.15,
    date: '2020-04-15',
  },
  {
    cases: 384,
    deaths: 13,
    recovered: 191,
    active: 180,
    tested: 6823,
    hospitalized: 73,
    icu: 23,
    growthFactor: 1.05,
    date: '2020-04-16',
  },
  {
    cases: 396,
    deaths: 13,
    recovered: 198,
    active: 185,
    tested: 7024,
    hospitalized: 77,
    icu: 23,
    growthFactor: 1.03,
    date: '2020-04-17',
  },
  {
    cases: 416,
    deaths: 13,
    recovered: 207,
    active: 196,
    tested: 7207,
    hospitalized: 79,
    icu: 23,
    growthFactor: 1.05,
    date: '2020-04-18',
  },
  {
    cases: 422,
    deaths: 13,
    recovered: 207,
    active: 202,
    tested: 7302,
    hospitalized: 81,
    icu: 23,
    growthFactor: 1.01,
    date: '2020-04-19',
  },
  {
    cases: 422,
    deaths: 13,
    recovered: 207,
    active: 202,
    tested: 7302,
    hospitalized: 81,
    icu: 23,
    growthFactor: 1,
    date: '2020-04-20',
  },
  {
    cases: 443,
    deaths: 13,
    recovered: 230,
    active: 200,
    tested: 7881,
    growthFactor: 1.05,
    date: '2020-04-21',
  },
  {
    cases: 451,
    deaths: 14,
    recovered: 243,
    active: 194,
    tested: 8053,
    hospitalized: 89,
    icu: 25,
    growthFactor: 1.02,
    date: '2020-04-22',
  },
  {
    cases: 465,
    deaths: 16,
    recovered: 245,
    active: 204,
    tested: 8256,
    hospitalized: 91,
    icu: 26,
    growthFactor: 1.03,
    date: '2020-04-23',
  },
  {
    cases: 476,
    deaths: 16,
    recovered: 255,
    active: 205,
    tested: 8503,
    hospitalized: 92,
    icu: 26,
    growthFactor: 1.02,
    date: '2020-04-24',
  },
  {
    cases: 476,
    deaths: 16,
    recovered: 255,
    active: 205,
    tested: 8503,
    hospitalized: 92,
    icu: 26,
    growthFactor: 1,
    date: '2020-04-25',
  },
  {
    cases: 476,
    deaths: 16,
    recovered: 255,
    active: 205,
    tested: 8503,
    hospitalized: 92,
    icu: 26,
    growthFactor: 1,
    date: '2020-04-26',
  },
  {
    cases: 503,
    deaths: 17,
    recovered: 271,
    active: 215,
    tested: 9282,
    growthFactor: 1.06,
    date: '2020-04-27',
  },
  {
    cases: 508,
    deaths: 17,
    recovered: 356,
    active: 135,
    tested: 9528,
    growthFactor: 1.01,
    date: '2020-04-28',
  },
  {
    cases: 517,
    deaths: 17,
    recovered: 366,
    active: 134,
    tested: 9784,
    growthFactor: 1.02,
    date: '2020-04-29',
  },
  {
    cases: 535,
    deaths: 18,
    recovered: 377,
    active: 140,
    tested: 9962,
    growthFactor: 1.03,
    date: '2020-04-30',
  },
  {
    cases: 544,
    deaths: 18,
    recovered: 385,
    active: 141,
    tested: 10207,
    growthFactor: 1.02,
    date: '2020-05-01',
  },
  {
    cases: 559,
    deaths: 19,
    recovered: 395,
    active: 145,
    tested: 10470,
    growthFactor: 1.03,
    date: '2020-05-02',
  },
  {
    cases: 566,
    deaths: 19,
    recovered: 402,
    active: 145,
    tested: 10861,
    growthFactor: 1.01,
    date: '2020-05-03',
  },
  {
    cases: 577,
    deaths: 19,
    recovered: 402,
    active: 156,
    tested: 11100,
    growthFactor: 1.02,
    date: '2020-05-04',
  },
  {
    cases: 595,
    deaths: 19,
    recovered: 416,
    active: 160,
    tested: 11854,
    growthFactor: 1.03,
    date: '2020-05-05',
  },
  {
    cases: 608,
    deaths: 19,
    recovered: 419,
    active: 170,
    tested: 12122,
    growthFactor: 1.02,
    date: '2020-05-06',
  },
  {
    cases: 631,
    deaths: 19,
    recovered: 430,
    active: 182,
    tested: 12465,
    growthFactor: 1.04,
    date: '2020-05-07',
  },
  {
    cases: 652,
    deaths: 19,
    recovered: 435,
    active: 198,
    tested: 12863,
    growthFactor: 1.03,
    date: '2020-05-08',
  },
  {
    cases: 666,
    deaths: 19,
    recovered: 447,
    active: 200,
    tested: 13188,
    growthFactor: 1.02,
    date: '2020-05-09',
  },
  {
    cases: 666,
    deaths: 19,
    recovered: 447,
    active: 200,
    tested: 13188,
    growthFactor: 1,
    date: '2020-05-10',
  },
  {
    cases: 678,
    deaths: 19,
    recovered: 458,
    active: 201,
    tested: 13715,
    growthFactor: 1.02,
    date: '2020-05-11',
  },
  {
    cases: 698,
    deaths: 19,
    recovered: 475,
    active: 204,
    tested: 13930,
    growthFactor: 1.03,
    date: '2020-05-12',
  },
  {
    cases: 725,
    deaths: 24,
    recovered: 477,
    active: 224,
    tested: 14384,
    growthFactor: 1.04,
    date: '2020-05-13',
  },
  {
    cases: 748,
    deaths: 25,
    recovered: 488,
    active: 235,
    tested: 14971,
    growthFactor: 1.03,
    date: '2020-05-14',
  },
  {
    cases: 764,
    deaths: 25,
    recovered: 502,
    active: 237,
    tested: 15550,
    hospitalized: 23,
    growthFactor: 1.02,
    date: '2020-05-15',
  },
  {
    cases: 764,
    deaths: 25,
    recovered: 502,
    active: 237,
    tested: 15550,
    hospitalized: 23,
    growthFactor: 1,
    date: '2020-05-16',
  },
  {
    cases: 764,
    deaths: 25,
    recovered: 502,
    active: 237,
    tested: 15550,
    hospitalized: 23,
    growthFactor: 1,
    date: '2020-05-17',
  },
  {
    cases: 764,
    deaths: 25,
    recovered: 502,
    active: 237,
    tested: 15550,
    hospitalized: 23,
    growthFactor: 1,
    date: '2020-05-18',
  },
  {
    cases: 837,
    deaths: 28,
    recovered: 542,
    active: 267,
    tested: 17498,
    hospitalized: 27,
    growthFactor: 1.1,
    date: '2020-05-19',
  },
  {
    cases: 837,
    deaths: 28,
    recovered: 542,
    active: 267,
    tested: 17498,
    hospitalized: 27,
    growthFactor: 1,
    date: '2020-05-20',
  },
  {
    cases: 883,
    deaths: 29,
    recovered: 680,
    active: 174,
    tested: 18673,
    hospitalized: 21,
    growthFactor: 1.05,
    date: '2020-05-21',
  },
  {
    cases: 912,
    deaths: 30,
    recovered: 691,
    active: 191,
    tested: 22685,
    hospitalized: 23,
    growthFactor: 1.03,
    date: '2020-05-22',
  },
  {
    cases: 912,
    deaths: 30,
    recovered: 691,
    active: 191,
    tested: 22685,
    hospitalized: 23,
    growthFactor: 1,
    date: '2020-05-23',
  },
  {
    cases: 912,
    deaths: 30,
    recovered: 691,
    active: 191,
    tested: 22685,
    hospitalized: 23,
    growthFactor: 1,
    date: '2020-05-24',
  },
  {
    cases: 912,
    deaths: 30,
    recovered: 691,
    active: 191,
    tested: 22685,
    hospitalized: 23,
    growthFactor: 1,
    date: '2020-05-25',
  },
  {
    cases: 1017,
    deaths: 32,
    recovered: 747,
    active: 238,
    tested: 22589,
    hospitalized: 27,
    growthFactor: 1.12,
    date: '2020-05-26',
  },
  {
    cases: 1017,
    deaths: 32,
    recovered: 747,
    active: 238,
    tested: 22589,
    hospitalized: 27,
    growthFactor: 1,
    date: '2020-05-27',
  },
  {
    cases: 1062,
    deaths: 32,
    recovered: 796,
    active: 234,
    tested: 23389,
    hospitalized: 20,
    growthFactor: 1.04,
    date: '2020-05-28',
  },
  {
    cases: 1078,
    deaths: 33,
    recovered: 817,
    active: 228,
    tested: 23830,
    hospitalized: 21,
    growthFactor: 1.02,
    date: '2020-05-29',
  },
  {
    cases: 1078,
    deaths: 33,
    recovered: 817,
    active: 228,
    tested: 23830,
    hospitalized: 21,
    growthFactor: 1,
    date: '2020-05-30',
  },
  {
    cases: 1078,
    deaths: 33,
    recovered: 817,
    active: 228,
    tested: 23830,
    hospitalized: 21,
    growthFactor: 1,
    date: '2020-05-31',
  },
  {
    cases: 1152,
    deaths: 34,
    recovered: 854,
    active: 264,
    tested: 27966,
    hospitalized: 18,
    growthFactor: 1.07,
    date: '2020-06-01',
  },
  {
    cases: 1164,
    deaths: 34,
    recovered: 886,
    active: 244,
    tested: 28367,
    hospitalized: 19,
    growthFactor: 1.01,
    date: '2020-06-02',
  },
  {
    cases: 1164,
    deaths: 34,
    recovered: 886,
    active: 244,
    tested: 28367,
    hospitalized: 19,
    growthFactor: 1,
    date: '2020-06-03',
  },
  {
    cases: 1164,
    deaths: 34,
    recovered: 886,
    active: 244,
    tested: 28367,
    hospitalized: 19,
    growthFactor: 1,
    date: '2020-06-04',
  },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-05',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-06',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-07',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-08',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-09',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-10',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-11',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-12',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-13',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-14',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-15',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-16',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-17',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-18',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-19',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-20',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-21',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-22',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-23',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-24',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-25',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-26',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-27',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-28',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-29',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-06-30',
  // },
  // {
  //   cases: 1164,
  //   deaths: 34,
  //   recovered: 886,
  //   active: 244,
  //   tested: 28367,
  //   hospitalized: 19,
  //   growthFactor: 1,
  //   date: '2020-07-01',
  // },
];
